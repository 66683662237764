<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="hidden"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    class="group"
    aria-hidden="true"
  >
    <path
      d="M38.134,4A1.134,1.134,0,0,0,37,5.134V25.547a1.134,1.134,0,1,0,2.268,0V5.134A1.134,1.134,0,0,0,38.134,4Z"
      transform="translate(-14.736 -0.601)"
      fill="currentColor"
    />
    <g>
      <path
        d="M15.789,12.341a1.191,1.191,0,0,0-1.531-.113,1.077,1.077,0,0,0-.113,1.7l3.742,3.742H8.134a1.134,1.134,0,1,0,0,2.268h9.753l-3.742,3.742a1.077,1.077,0,0,0,.113,1.7,1.191,1.191,0,0,0,1.531-.113L21.4,19.6a1.077,1.077,0,0,0,0-1.588Z"
        transform="translate(-1.747 -4.065)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
